<template>
  <div id="reservation-list">
    <v-card>
      <v-card-title>予約商品(PreOrder)一覧</v-card-title>

      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          md="3"
        >
          <v-autocomplete
            v-model="pellet_id"
            :items="pelletIds"
            placeholder="PelletID"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-autocomplete
            v-model="po_code"
            :items="preorderCodes"
            placeholder="予約商品"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="dateStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                placeholder="予約開始日From"
                dense
                hide-details
                outlined
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              @input="dateStartMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                placeholder="予約終了日To"
                dense
                outlined
                hide-details
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="dateEndMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :to="{ name: 'preorder-create' }"
          >
            <span>NEW</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mb-4"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="preorderList"
        :search="searchQuery"
      >
        <template v-slot:[`item.po_code`]="{ item }">
          <v-tooltip bottom max-width="18%">
            <template v-slot:activator="{ on, attrs }">
              <label
                for="reserve_id"
                v-bind="attrs"
                v-on="on"
              >{{ item.po_code }}</label>
            </template>
            <span>{{ item.po_name_ja }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.po_weight`]="{ item }">
          <label>{{ item.po_weight ? formatData(item.po_weight) : '0' }}</label>
        </template>
        <template v-slot:[`item.po_price`]="{ item }">
          <label>￥{{ item.po_price ? formatData(item.po_price) : '0' }}</label>
        </template>
        <template v-slot:[`item.po_price_usd`]="{ item }">
          <label>${{ item.po_price_usd ? formatData(item.po_price_usd) : '0' }}</label>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <pre-order-preview
              :id="item.po_id"
              class="py-2 pl-1"
              @closed="loadData"
            >
            </pre-order-preview>
            <div class="py-2 pl-1">
              <v-icon
                small
                @click="editItem(item)"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </div>
            <delete-form
              :id="item.po_id"
              class="py-2 pl-1"
              :icon="icons.mdiDelete"
              @closed="loadData"
            ></delete-form>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import {
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiExportVariant,
  mdiCalendar,
} from '@mdi/js'
import {
  mapActions, mapState, mapMutations,
} from 'vuex'
import DeleteForm from './PreOrderDelete.vue'
import PreOrderPreview from './PreOrderPreview.vue'

export default {
  components: {
    DeleteForm,
    PreOrderPreview,
  },
  data: () => ({
    icons: {
      mdiEye,
      mdiPencil,
      mdiDelete,
      mdiExportVariant,
      mdiCalendar,
    },
    startDate: '',
    endDate: '',
    dateEndMenu: '',
    dateStartMenu: '',
    product_id: '',
    pellet_id: '',
    searchQuery: '',

    preorderCodes: [],
    po_code: '',
    headers: [
      {
        text: 'ID',
        align: 'left',
        value: 'po_id',
        width: '8%',
        fixed: true,
      },
      {
        text: 'ペレットID',
        value: 'pellet_id',
        align: 'left',
        width: '12%',
        fixed: true,
      },
      {
        text: '商品コード',
        align: 'left',
        value: 'po_code',
        width: '13%',
        fixed: true,
      },
      {
        text: '重量(F)',
        value: 'po_weight',
        align: 'right',
        width: '8%',
        fixed: true,
      },
      {
        text: '￥/KG',
        value: 'po_price',
        align: 'right',
        width: '10%',
        fixed: true,
      },
      {
        text: '$/ton',
        value: 'po_price_usd',
        align: 'right',
        width: '10%',
        fixed: true,
      },
      {
        text: '下限',
        value: 'po_min_qty',
        sortable: false,
        align: 'right',
        width: '6%',
        fixed: true,
      },
      {
        text: '上限',
        value: 'po_max_qty',
        sortable: false,
        align: 'right',
        width: '6%',
        fixed: true,
      },
      {
        text: '予約開始日',
        value: 'po_ssd',
        align: 'left',
        width: '11%',
        fixed: true,
      },
      {
        text: '予約終了日',
        value: 'po_sed',
        align: 'left',
        width: '11%',
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '5%',
        fixed: true,
      },
    ],
  }),
  computed: {
    ...mapState('pelletStore', ['pelletIds']),
    ...mapState('preorderStore', ['preorderList']),

    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
  },

  watch: {
    po_code(val) {
      this.changeRouteQuery('po_code', val)
      this.loadData()
    },
    pellet_id(val) {
      this.changeRouteQuery('pellet_id', val)
      this.loadData()
    },
    startDate(val) {
      this.changeRouteQuery('startDate', val)
      this.loadData()
    },
    endDate(val) {
      this.changeRouteQuery('endDate', val)
      this.loadData()
    },
  },

  created() {
    this.loadQuery()
    this.loadPelletIds()
    this.loadData()
  },
  destoryed() {
    this.clearPreorders()
    this.clearPelletIds()
  },

  methods: {
    ...mapActions('pelletStore', ['loadPelletIds']),
    ...mapActions('preorderStore', ['loadPreorders']),
    ...mapMutations('preorderStore', ['clearPreorders']),
    ...mapMutations('pelletStore', ['clearPelletIds']),
    ...mapMutations('app', ['setOverlayStatus']),
    editItem(item) {
      console.log('item', item)
      this.$router.push({
        name: 'preorder-edit',
        params: {
          id: item.po_id, query: this.$route.query,
        },
      })
    },
    loadQuery() {
      this.pellet_id = this.$route.query.pellet_id ?? ''
      this.startDate = this.$route.query.start_date ?? ''
      this.endDate = this.$route.query.end_date ?? ''
    },
    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }
      this.$router.push({
        path: this.$route.path, query,
      })
    },
    loadData() {
      this.setOverlayStatus(true)
      this.loadPreorders(this.$route.query)
        .then(() => {
          this.preorderCodes = this.preorderList.map(pl => pl.po_code)
        })
        .finally(() => {
          this.setOverlayStatus(false)
          console.log('preorderList', this.preorderList)
        })
    },
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
</style>
