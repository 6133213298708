<template>
  <div justify="center">
    <v-icon
      small
      @click.stop="openForm"
    >
      {{ icon }}
    </v-icon>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span
            class="text-h5"
            style="color: red"
          >削除</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <template v-if="apierror.status == 'error'">
              <div v-for="msg in apierror.messages" :key="msg">
                <v-row class="ml-6 mb-3 ma-3">
                  <span style="color: red">* {{ msg }} </span>
                </v-row>
              </div>
            </template>
            <v-row>
              <v-col cols="4">
                <span>予約商品</span>
              </v-col>
              <v-col cols="8">
                {{ selectedPreorder.po_id }} ({{ selectedPreorder.po_code }})
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <span>Pellet</span>
              </v-col>
              <v-col cols="8">
                {{ selectedPreorder.pellet_id }}
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <span>予約商品名</span>
              </v-col>
              <v-col cols="8">
                {{ selectedPreorder.po_name_ja }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <span>概要</span>
              </v-col>
              <v-col cols="8">
                {{ selectedPreorder.po_ovv_ja }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :loading="submitStatus"
            @click="deleteItem(selectedPreorder)"
          >
            YES
          </v-btn>
          <v-btn
            color="error"
            @click="closeForm()"
          >
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiPlus, mdiCalendar,
} from '@mdi/js'
import {
  mapActions, mapState, mapMutations,
} from 'vuex'

export default {
  props: ['icon', 'id'],
  data: () => ({
    icons: {
      mdiPlus,
      mdiCalendar,
    },
    submitStatus: false,
    dialog: false,
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
  }),
  computed: {
    ...mapState('preorderStore', ['selectedPreorder']),
  },
  methods: {
    ...mapActions('preorderStore', ['deletePreorder', 'loadPreorder']),
    ...mapMutations('preorderStore', ['clearSelectedPreorder']),
    ...mapMutations('app', ['setOverlayStatus']),
    deleteItem(selectedPreorder) {
      this.submitStatus = true
      this.deletePreorder(selectedPreorder.po_id)
        .then(() => {
          this.closeForm()
        })
        .catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.messages = error.response.data.message
          }
        }).finally(() => {
          this.submitStatus = false
        })
    },
    openForm() {
      this.setOverlayStatus(true)
      this.loadPreorder(this.id)
        .catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.messages = error.response.data.message
          }
        }).finally(() => {
          this.dialog = true
          this.setOverlayStatus(false)
        })
    },
    closeForm() {
      this.$emit('closed')
      this.apierror.messages = []
      this.dialog = false
      this.clearSelectedPreorder()
    },
  },
}
</script>
